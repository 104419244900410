/* eslint-disable no-restricted-globals */
import * as firebase from "firebase/app";
import "firebase/firestore";
import PropTypes from "prop-types";
import React from "react";
import ContentEditable from "react-contenteditable";
import "./App.css";

const search = new URLSearchParams(location.search);
const COLLECTION_ID_NAME = 'flow';
const COLLECTION_ID = search.get(COLLECTION_ID_NAME) || "nodes";

const firebaseConfig = {
  apiKey: "AIzaSyCEGZaYS4GJ_W28xnfA-6wvstbTXp6HIVs",
  authDomain: "minds-8ce9b.firebaseapp.com",
  databaseURL: "https://minds-8ce9b.firebaseio.com",
  projectId: "minds-8ce9b",
  storageBucket: "",
  messagingSenderId: "474796313715",
  appId: "1:474796313715:web:a36e22e8801ff825"
};

firebase.initializeApp(firebaseConfig);

const database = firebase.firestore();

const getPositionStyle = ({ pageX, pageY }) => {
  return { left: pageX, top: pageY };
};

class Identity {
  constructor(id, i) {
    this.value = id || i;
  }

  prefixed = function(prefix) {
    return prefix ? `${prefix}-${this.value}` : `${this.value}`;
  };
}

const positionType = PropTypes.exact({
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired
}).isRequired;

class Editor extends React.Component {
  state = {
    value: this.props.defaultValue
  };

  input = React.createRef();

  componentDidMount() {
    if (this.input) {
      this.input.current.focus();
    }
  }

  handleInputChange = event => {
    const { value } = event.target;
    this.setState({
      value
    });
  };

  handleInputKeyUp = event => {
    if (event.key === "Escape") {
      this.handleTextAction();
    }
  };

  handleTextAction() {
    const { value } = this.state;
    const { onRemove, onChange, onCancel } = this.props;
    if (!value) {
      onRemove();
    } else {
      if (this.textChanged()) {
        onChange(value);
      } else {
        onCancel();
      }
    }
  }

  handleInputBlur = () => {
    this.handleTextAction();
  };

  textChanged() {
    const { defaultValue } = this.props;
    return this.state.value !== defaultValue;
  }

  render() {
    const { value } = this.state;
    const { id, position } = this.props;
    return (
      <ContentEditable
        id={id}
        className="input"
        style={position}
        innerRef={this.input}
        html={value}
        onChange={this.handleInputChange}
        onKeyUp={this.handleInputKeyUp}
        onBlur={this.handleInputBlur}
      />
    );
  }
}

Editor.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  position: positionType
};

function Node({ text, position, ...others }) {
  return (
    <div
      {...others}
      className="text"
      style={position}
      dangerouslySetInnerHTML={{
        __html: text || '<i style="color: grey;">_Empty_</i>'
      }}
    />
  );
}

Node.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  position: positionType
};

class App extends React.Component {
  state = {
    nodes: []
  };

  addNode(node = {}) {
    return database
      .collection(COLLECTION_ID)
      .add(node)
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  }

  updateNode(id, node = {}) {
    return database
      .collection(COLLECTION_ID)
      .doc(id)
      .update(node);
  }

  deleteNode(id) {
    return database
      .collection(COLLECTION_ID)
      .doc(id)
      .delete();
  }

  listenNodesChange() {
    database.collection(COLLECTION_ID).onSnapshot(querySnapshot => {
      const { nodes } = this.state;
      let newNodes = [...nodes];
      querySnapshot.docChanges().forEach(change => {
        const doc = change.doc;
        const id = doc.id;
        const data = doc.data();
        const newNode = {
          ...data,
          id
        };
        const nodeExists = nodes.some(({ id: existsID }) => existsID === id);
        if (change.type === "added" && !nodeExists) {
          newNodes.push(newNode);
        }
        if (change.type === "modified" && nodeExists) {
          newNodes = newNodes.map(node => (node.id === id ? newNode : node));
        }
        if (change.type === "removed" && nodeExists) {
          newNodes = newNodes.filter(node => node.id !== id);
        }
      });
      this.setState({
        nodes: newNodes
      });
    });
  }

  handleCanvasKeyup = event => {
    const focusedNode = document.querySelector(":focus");
    console.log(
      `press keyup: ${event.key}, focusing on node: `,
      focusedNode ? focusedNode.id : null
    );
  };

  componentDidMount() {
    document.addEventListener("click", this.handleCanvasClick);
    document.addEventListener("keyup", this.handleCanvasKeyup);
    this.listenNodesChange();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleCanvasClick);
    document.removeEventListener("keyup", this.handleCanvasKeyup);
  }

  handleCanvasClick = event => {
    const { target, pageX, pageY } = event;
    const { nodes } = this.state;
    const clickOnNode =
      target.className === "input" || target.closest(".text, .editor");
    const editing = nodes.some(node => node.editing);
    if (clickOnNode || editing) {
      return;
    }
    const node = { pageX, pageY, text: "", editing: true };
    this.setState({
      nodes: [...nodes, node]
    });
  };

  renderHint = () => {
    return <div id="hint">点击任意处开始</div>;
  };

  getNodeToStore({ pageX, pageY, text }) {
    return { pageX, pageY, text };
  }

  handleNodeTextChange = (i, node) => text => {
    const { id } = node;
    const newNode = { ...this.getNodeToStore(node), text };
    if (!id) {
      // Add node, remove from memory then fetch from firebase
      this.removeNodeByIndex(i);
      this.addNode(newNode).catch(function(error) {
        console.error("Error adding document: ", error);
      });
    } else {
      // Update node
      this.updateNode(id, newNode).catch(function(error) {
        console.error("Error updating document: ", error);
      });
    }
  };

  removeNodeByIndex = i => {
    const { nodes } = this.state;
    this.setState({
      nodes: nodes.filter((_, index) => i !== index)
    });
  };

  handleNodeRemove = (i, id) => () => {
    if (id) {
      this.deleteNode(id);
    } else {
      this.removeNodeByIndex(i);
    }
  };

  handleNodeEditingCancel = (i, id) => () => {
    this.changeNodeEditing(false, i, id).then(() => {
      const identity = new Identity(id, i);
      const nodeDom = document.querySelector(identity.prefixed("#node"));
      if (nodeDom) {
        nodeDom.focus();
      }
    });
  };

  handleNodeClick = (i, id) => () => {
    this.changeNodeEditing(true, i, id);
  };

  changeNodeEditing(editing, i, id) {
    const { nodes } = this.state;
    return new Promise(resolve => {
      this.setState(
        {
          nodes: nodes.map((node, index) =>
            editing
              ? { ...node, editing: node.id === id || index === i }
              : { ...node, editing: false }
          )
        },
        resolve
      );
    });
  }

  renderNode = (node, i) => {
    const { id, pageX, pageY, text = "", editing = false } = node;
    const position = getPositionStyle({ pageX, pageY });
    const prefixedIdentity = new Identity(id, i).prefixed(
      editing ? "editor" : "node"
    );
    if (editing) {
      return (
        <Editor
          id={prefixedIdentity}
          key={prefixedIdentity}
          position={position}
          defaultValue={text}
          onChange={this.handleNodeTextChange(i, node)}
          onRemove={this.handleNodeRemove(i, id)}
          onCancel={this.handleNodeEditingCancel(i, id)}
        />
      );
    }
    return (
      <Node
        id={prefixedIdentity}
        key={prefixedIdentity}
        text={text}
        position={position}
        onClick={this.handleNodeClick(i, id)}
        tabIndex={i + 1}
      />
    );
  };

  renderNodes = nodes => {
    return (nodes || []).map(this.renderNode);
  };

  render() {
    const { nodes } = this.state;
    return <div className="App">{this.renderNodes(nodes)}</div>;
  }
}

export default App;
